import React, { useState } from 'react';

function MeasurementToggle({ unit, setUnit }) {
  const [showFire, setShowFire] = useState(false);

  const handleSwitchChange = (e) => {
    const newUnit = e.target.checked ? 'Imperial' : 'Metric';
    if (newUnit === 'Imperial') {
      setShowFire(true);
      setTimeout(() => {
        setShowFire(false);
        setUnit('Metric'); // Automatically switch back to Metric
      }, 5000); // Fire animation and message duration
    } else {
      setUnit(newUnit);
    }
  };

  return (
    <div className="measurement-toggle mb-4">
      <div className="switch-wrapper">
        <label className={`switch-label ${unit === 'Metric' ? 'active' : ''}`}>
          Metric
        </label>
        <input
          className="switch-input"
          type="checkbox"
          id="unitSwitch"
          checked={unit === 'Imperial'}
          onChange={handleSwitchChange}
        />
        <label className={`switch-label ${unit === 'Imperial' ? 'active' : ''}`}>
          Imperial
        </label>
      </div>

      {showFire && (
        <div className="fire-overlay active">
          Never use the imperial system when cooking!
        </div>
      )}
    </div>
  );
}

export default MeasurementToggle;