import React from 'react';
import '../ResultDisplay.css';

function ResultDisplay({ result, yeastType }) {
  // Function to format numbers
  const formatNumber = (value) => {
    const number = parseFloat(value); // Ensure the value is a number

    if (isNaN(number)) {
      return value; // If the value is not a number, return it as is
    }

    if (number < 10) {
      return number.toFixed(1).toLocaleString();
    } else {
      return Math.round(number).toLocaleString();
    }
  };

  return (
    <div className="result-display card p-4 shadow-sm">
      <h2 className="result-title mb-4">Dough Recipe</h2>
      <div className="result-item">
        <img src="../icons/flour.png" alt="Flour" className="result-icon" />
        <span className="result-label">Flour</span>
        <strong className="result-value">{result.flour} gr</strong>
      </div>
      <div className="result-item">
        <img src="../icons/water.png" alt="Water" className="result-icon" />
        <span className="result-label">Water</span>
        <strong className="result-value">{result.water} gr</strong>
      </div>
      <div className="result-item">
        <img src="../icons/salt.png" alt="Salt" width="24" height="24" className="result-icon" />
        <span className="result-label">Salt</span>
        <strong className="result-value">{result.salt} gr</strong>
      </div>
      <div className="result-item">
        <img src="../icons/yeast.png" alt={yeastType} className="result-icon" />
        <span className="result-label">{yeastType}</span>
        <strong className="result-value">{result.yeast} gr</strong>
      </div>
    </div>
  );
}

export default ResultDisplay;