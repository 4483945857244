// src/components/InputField.js
import React from 'react';

function InputField({ label, value, setValue }) {
  return (
    <div className="form-group">
      <label>{label}</label>
      <input
        type="number"
        className="form-control"
        value={value}
        onChange={(e) => setValue(parseFloat(e.target.value))}
      />
    </div>
  );
}

export default InputField;