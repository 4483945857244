import React, { useState, useEffect } from 'react';
import MeasurementToggle from './components/MeasurementToggle';
import Dropdown from './components/Dropdown';
import InputField from './components/InputField';
import ResultDisplay from './components/ResultDisplay';
import './App.css';
import { Analytics } from "@vercel/analytics/react"

// Utility function to format numbers with commas and correct decimal places
function formatNumber(number) {
  if (number < 10) {
    return number.toFixed(1); // One decimal place if the number is less than 10
  }
  return Math.round(number).toLocaleString(); // Round and format with commas if needed
}

function App() {
  const [unit, setUnit] = useState('Metric');
  const [pizzaStyle, setPizzaStyle] = useState('Neapolitan');
  const [yeastType, setYeastType] = useState('Instant Dry Yeast');
  const [fermentationType, setFermentationType] = useState('Same Day Dough'); // New state
  const [numPizzas, setNumPizzas] = useState(1);
  const [doughWeight, setDoughWeight] = useState(230);
  const [waterPercentage, setWaterPercentage] = useState(65);
  const [result, setResult] = useState(null);

  const calculate = () => {
    const totalDough = doughWeight * numPizzas;
    const totalFlour = totalDough / (1 + waterPercentage / 100);
    const totalWater = totalFlour * (waterPercentage / 100);

    // Yeast calculation based on type and fermentation type
    let yeastPercentage;
    if (fermentationType === 'Overnight Ferment') {
      switch (yeastType) {
        case 'Instant Dry Yeast':
          yeastPercentage = 0.05; // Lower yeast for overnight fermentation
          break;
        case 'Active Dry Yeast':
          yeastPercentage = 0.075;
          break;
        case 'Fresh Yeast':
          yeastPercentage = 0.15;
          break;
        case 'Sourdough Starter':
          yeastPercentage = 10;
          break;
        default:
          yeastPercentage = 0.05;
      }
    } else {
      // Same Day Dough
      switch (yeastType) {
        case 'Instant Dry Yeast':
          yeastPercentage = 0.1;
          break;
        case 'Active Dry Yeast':
          yeastPercentage = 0.15;
          break;
        case 'Fresh Yeast':
          yeastPercentage = 0.25;
          break;
        case 'Sourdough Starter':
          yeastPercentage = 20;
          break;
        default:
          yeastPercentage = 0.1;
      }
    }

    const totalYeast = totalFlour * (yeastPercentage / 100);

    // Salt calculation
    const saltPercentage = 2.5; // 2.5% of flour weight
    const totalSalt = totalFlour * (saltPercentage / 100);

    setResult({
      flour: formatNumber(totalFlour),
      water: formatNumber(totalWater),
      yeast: formatNumber(totalYeast),
      salt: formatNumber(totalSalt),
      fermentationType, // Passing fermentation type to result
      yeastInstruction: yeastType === 'Sourdough Starter' ? 'Mix in the sourdough starter.' : 'Sprinkle the yeast evenly over the flour.'
    });
  };

  // Use useEffect to calculate results on first load and when any input changes
  useEffect(() => {
    calculate(); // Calculate on first load and any input change
  }, [pizzaStyle, yeastType, fermentationType, numPizzas, doughWeight, waterPercentage]);

  return (
    <div className="container mt-5">
      <div className="text-center mb-4">
        <h2 className="logo">pizzamühle</h2>  {/* Centered Logo */}
        <hr /> {/* Breakline */}
      </div>

      <div className="row justify-content-center">
        {/* Calculator Form */}
        <div className="col-md-6 col-lg-5 mb-4">
          <h3 className="text-left">Pizza Dough Calculator</h3> {/* Left-aligned title */}

          <MeasurementToggle
            unit={unit}
            setUnit={setUnit}
          />

          <Dropdown
            label="Pizza Style"
            value={pizzaStyle}
            setValue={setPizzaStyle}
            options={['Neapolitan']} // Only Neapolitan available
          />

          <Dropdown
            label="Type of Yeast"
            value={yeastType}
            setValue={setYeastType}
            options={['Instant Dry Yeast', 'Active Dry Yeast', 'Fresh Yeast', 'Sourdough Starter']}
          />

          <Dropdown
            label="Fermentation Type" // New dropdown for fermentation type
            value={fermentationType}
            setValue={setFermentationType}
            options={['Same Day Dough', 'Overnight Ferment']}
          />

          <InputField
            label="Number of Pizzas"
            value={numPizzas}
            setValue={setNumPizzas}
          />

          <InputField
            label="Weight of Doughball (g)"
            value={doughWeight}
            setValue={setDoughWeight}
          />

          <InputField
            label="Water (%)"
            value={waterPercentage}
            setValue={setWaterPercentage}
          />
        </div>

        {/* Results Section */}
        {result && (
          <div className="col-md-5 col-lg-4 align-self-start mt-4">
            <ResultDisplay result={result} yeastType={yeastType} />
          </div>
        )}
      </div>

      {/* Additional breakline */}
      <hr className="mt-5" />

      {/* Heading for the guide */}
      <div className="text-center mt-4">
        <h3>Now let's make your pizza. Step by step guide on how to make your pizza dough</h3>
      </div>

      {/* Step by Step Guide */}
      {result && (
        <div className="step-by-step mt-4">
          <div className="step-content">
            <span className="step-number">1</span>
            <h4>Mix the Ingredients</h4>
          </div>
          <p>Combine {result.flour} grams of flour with {result.water} grams of water in a large mixing bowl.</p>
          <p>{result.yeastInstruction} Add {result.yeast} grams of yeast to the dough.</p>
          <p>Incorporate {result.salt} grams of salt into the dough.</p>

          <div className="step-content">
            <span className="step-number">2</span>
            <h4>Knead the Dough</h4>
          </div>
          <p>Turn the dough out onto a lightly floured surface and knead it for about 10-15 minutes until smooth and elastic.</p>

          <div className="step-content">
            <span className="step-number">3</span>
            <h4>First Rise ({fermentationType === 'Overnight Ferment' ? 'Slow Fermentation' : 'Fermentation'})</h4>
          </div>
          <p>Place the dough in a lightly oiled bowl, cover it, and let it rise for {fermentationType === 'Overnight Ferment' ? '12 to 24 hours in the fridge' : '1 to 2 hours at room temperature'} until doubled in size.</p>

          <div className="step-content">
            <span className="step-number">4</span>
            <h4>Shape the Dough</h4>
          </div>
          <p>After the first rise, divide the dough into portions (typically {doughWeight} grams each) and shape each portion into a ball. Let the dough balls rest for 10-15 minutes.</p>

          <div className="step-content">
            <span className="step-number">5</span>
            <h4>Prepare for Baking</h4>
          </div>
          <p>Preheat your oven to its highest setting (usually 250-300°C or 475-500°F). Prepare your desired toppings.</p>

          <div className="step-content">
            <span className="step-number">6</span>
            <h4>Top and Bake the Pizza</h4>
          </div>
          <p>Spread your toppings over the dough and bake for 8-12 minutes or until the crust is golden brown and the cheese is bubbling.</p>

          <div className="step-content">
            <span className="step-number">7</span>
            <h4>Serve and Enjoy</h4>
          </div>
          <p>Slice the pizza and enjoy your homemade creation!</p>
        </div>
      )}
      <Analytics/>
    </div>
  );
}

export default App;